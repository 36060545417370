<template lang="pug">
  v-card
    v-scrollable(
      id="scroll-panel"
      :bottom-offset="100")
      v-card-head
        h2.title {{ title }}
      v-card-content
        .avatar-wrapper
          v-image-select(
            :size="120"
            :value="profilePicture"
            @input="cropSuccess")
          .form-text.invalid {{ 'profilePictureUrl' | errorText }}
        .row
          .col-md-6
            .form-group
              label.required {{ 'ui.labels.first_name' | translate }}
              input.form-control.text-input(
                type="text"
                tabindex="1"
                :placeholder="'ui.inputs.enter_first_name' | translate"
                :readonly="isThirdPartyLoginEnabled"
                v-model="userInfoForm.firstName")
              span.form-text.invalid {{ 'firstName' | errorText }}
            .form-group
              label.required {{ 'ui.labels.e_mail' | translate }}
              input.form-control.text-input(
                type="text"
                tabindex="3"
                :placeholder="'ui.inputs.enter_e_mail' | translate"
                :readonly="isThirdPartyLoginEnabled"
                v-model="userInfoForm.email")
              span.form-text.invalid {{ 'email' | errorText }}
            .form-group
              label {{ 'ui.labels.department' | translate }}
              v-select(
                single
                label="name"
                fixed
                nullable
                :tabindex="5"
                :enableCreteOption='true'
                @on-create="onAddNewDepartment"
                :clearable="false"
                :placeholder="'ui.dropdown.select_department' | translate"
                :options="departments"
                v-model="userInfoForm.department")
              span.form-text.invalid {{ 'departmentId' | errorText }}
            .form-group
              label {{ 'ui.labels.location' | translate }}
              v-select(
                single
                fixed
                label="name"
                :tabindex="7"
                :enableCreteOption='true'
                @on-create="onAddNewLocation"
                :clearable="false"
                :placeholder="'ui.dropdown.select_location' | translate"
                :options="locations"
                v-model="userInfoForm.location")
              span.form-text.invalid {{ 'locationId' | errorText }}

          .col-md-6
            .form-group
              label.required {{ 'ui.labels.last_name' | translate }}
              input.form-control.text-input(
                type="text"
                tabindex="2"
                :placeholder="'ui.inputs.enter_last_name' | translate"
                :readonly="isThirdPartyLoginEnabled"
                v-model="userInfoForm.lastName")
              span.form-text.invalid {{ 'lastName' | errorText }}
            .form-group
              label {{ 'ui.labels.phone_number' | translate }}
              input.form-control(
                tabindex="4"
                :placeholder="'ui.inputs.enter_phone_number' | translate"
                v-model="userInfoForm.phoneNumber")
              span.form-text.invalid {{ 'phoneNumber' | errorText }}
            .form-group
              label {{ 'ui.labels.position' | translate }}
              v-select(
                single
                fixed
                nullable
                label="name"
                :tabindex="6"
                :clearable="false"
                :enableCreteOption="true"
                @on-create="onAddNewPosition"
                :placeholder="'ui.dropdown.select_position' | translate"
                :options="filteredPositions"
                v-model="userInfoForm.position")
              span.form-text.invalid {{ 'positionId' | errorText }}
            .form-group
              label {{ 'ui.labels.description' | translate }}
              textarea.form-control(
                rows="4"
                tabindex="8"
                :placeholder="'ui.inputs.enter_description' | translate"
                v-model="userInfoForm.description")
              span.form-text.invalid {{ 'descriptionId' | errorText }}

    v-card-actions(fixed)
      v-btn(
        text
        @click="cancelChanges")
        | {{ 'ui.buttons.cancel' | translate }}

      v-btn(
        @click="saveChanges"
        :loading="loading")
        | {{ isCreationMode ? $t('ui.buttons.create') : $t('ui.buttons.apply') }}

</template>

<script>
import api from '@/api'

import helpers from '@/util/helpers'
import { mapGetters, mapActions } from 'vuex'

import VImageSelect from '@/components/ui/v-image-select/VImageSelect'

export default {
  name: 'EmployeeDetail',

  components: {
    VImageSelect
  },

  async mounted () {
    if (!this.isCreationMode) {
      await this.openEmployee(this.employeeId)
      this.initForm()
    }
    this.clearErrors()
    this.loadDepartments()
    this._loadPositions()
    this.loadLocations()
  },

  destroyed () {
    this.clearErrors()
  },

  data: (vm) => ({
    imageCrop: {
      imgDataUrl: null
    },
    userInfoForm: {
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      phoneNumber: '',
      department: null,
      location: null,
      position: null,
      profilePictureUrl: ''
    },
    loading: false,
    filteredPositions: [],
    nullablePosition: { id: null, name: vm.$t('ui.labels.none'), department: null }
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    ...mapActions('employees', [
      'openEmployee',
      'addEmployee',
      'updateEmployee'
    ]),

    ...mapActions('positions', [
      'loadPositions'
    ]),

    ...mapActions('locations', [
      'loadLocations'
    ]),

    ...mapActions('departments', [
      'loadDepartments'
    ]),

    ...mapActions('organization', [
      'updateEmployeeCount'
    ]),

    async initForm () {
      this.imageCrop.imgDataUrl = null
      this.userInfoForm.firstName = this.currentEmployee.firstName
      this.userInfoForm.lastName = this.currentEmployee.lastName
      this.userInfoForm.email = this.currentEmployee.email
      this.userInfoForm.description = this.currentEmployee.description
      this.userInfoForm.phoneNumber = this.currentEmployee.phoneNumber
      if (!this.currentEmployee.department) {
        if (this.currentEmployee.position && this.currentEmployee.position.department) {
          this.userInfoForm.department = this.currentEmployee.position.department
        } else {
          this.userInfoForm.department = { ...this.nullablePosition }
        }
      } else {
        this.userInfoForm.department = this.currentEmployee.department
      }
      this.userInfoForm.location = this.currentEmployee.location
      this.userInfoForm.position = this.currentEmployee.position || { ...this.nullablePosition }
      this.userInfoForm.profilePictureUrl = this.currentEmployee.profilePictureUrl
    },

    async cropSuccess (imgDataUrl) {
      this.imageCrop.imgDataUrl = imgDataUrl
      if (!this.isCreationMode) {
        if (imgDataUrl) {
          let file = helpers.dataURLtoFile(this.imageCrop.imgDataUrl, 'profile.png')
          await api.updateEmployeePicture(this.employeeId, file)
        } else {
          await api.deleteEmployeePicture(this.employeeId)
        }
      }
    },

    async saveChanges () {
      this.loading = true
      this.clearErrors()

      // Check for position / deartment changes
      const prevDepartment = this.currentEmployee && this.currentEmployee.department && this.currentEmployee.department.id
      const prevPosition = this.currentEmployee && this.currentEmployee.position && this.currentEmployee.position.id
      const newDepartment = this.userInfoForm.department && this.userInfoForm.department.id
      const newPosition = this.userInfoForm.position && this.userInfoForm.position.id

      if (prevPosition && prevPosition !== newPosition) { // if position changed
        this.updateEmployeeCount({
          id: prevPosition,
          count: -1,
          isPosition: true
        })
        if (newPosition) {
          this.updateEmployeeCount({
            id: newPosition,
            count: 1,
            isPosition: true
          })
        } else {
          this.updateEmployeeCount({
            id: newDepartment,
            count: 1
          })
        }
      } else {
        this.updateEmployeeCount({
          id: prevDepartment,
          count: -1
        })
        this.updateEmployeeCount({
          id: newDepartment,
          count: 1
        })
      }

      let data = {
        firstName: this.userInfoForm.firstName,
        lastName: this.userInfoForm.lastName,
        email: this.userInfoForm.email,
        description: this.userInfoForm.description,
        phoneNumber: this.userInfoForm.phoneNumber
      }

      const dataForStore = { ...data,
        department: this.userInfoForm.department,
        location: this.userInfoForm.location,
        position: this.userInfoForm.position
      }
      if (this.userInfoForm.department) {
        data[this.userInfoForm.department.isCreated ? 'department' : 'departmentId'] = this.userInfoForm.department.isCreated
          ? { name: this.userInfoForm.department.name } : this.userInfoForm.department.id
      }

      if (this.userInfoForm.location) {
        data[this.userInfoForm.location.isCreated ? 'location' : 'locationId'] = this.userInfoForm.location.isCreated
          ? { name: this.userInfoForm.location.name } : this.userInfoForm.location.id
      }

      if (this.userInfoForm.position) {
        data[this.userInfoForm.position.isCreated ? 'position' : 'positionId'] = this.userInfoForm.position.isCreated
          ? { name: this.userInfoForm.position.name } : this.userInfoForm.position.id
      }

      let updateResult = null
      if (this.isCreationMode) {
        data.isActive = true
        const result = await this.addEmployee({ body: data, dataForStore })
        if (result) {
          data = result
          updateResult = result
        }
      } else {
        dataForStore.id = +this.employeeId
        data.id = this.employeeId
        updateResult = await this.updateEmployee({ payload: data, dataForStore })
      }

      if (data.id && this.imageCrop.imgDataUrl !== null && this.isCreationMode && updateResult) {
        let file = helpers.dataURLtoFile(this.imageCrop.imgDataUrl, 'profile.png')
        await api.updateEmployeePicture(data.id, file)
        this.imageCrop.imgDataUrl = null
      }
      this.loading = false

      if (updateResult) {
        this.$router.go(-1)
      }
    },

    cancelChanges () {
      this.$router.go(-1)
    },

    async  onAddNewDepartment (name) {
      let body = {
        name: name,
        id: name,
        isCreated: true
      }
      this.departments.push(body)
      this.userInfoForm.department = body
    },

    async  onAddNewLocation (name) {
      let body = {
        name: name,
        id: name,
        isCreated: true
      }

      this.locations.push(body)
      this.userInfoForm.location = body
    },

    async  onAddNewPosition (name) {
      let body = {
        name: name,
        id: name,
        isCreated: true,
        department: null
      }
      this.filteredPositions.push(body)
      this.positions.push(body)
      this.userInfoForm.position = body
    },

    async _loadPositions () {
      await this.loadPositions()
      if (!this.userInfoForm.department) {
        this.filteredPositions = [...this.positions]
      } else {
        this.filteredPositions = [...this.positions.filter(el => (el.department && el.department.id) ===
          this.userInfoForm.department.id
        )]
      }
    }

  },

  computed: {
    ...mapGetters('positions', [
      'positions'
    ]),

    ...mapGetters('locations', [
      'locations'
    ]),

    ...mapGetters('departments', [
      'departments'
    ]),

    ...mapGetters('employees', [
      'currentEmployee'
    ]),

    ...mapGetters('app', [
      'isThirdPartyLoginEnabled'
    ]),

    profilePicture () {
      return this.imageCrop.imgDataUrl === null
        ? this.userInfoForm.profilePictureUrl
        : this.imageCrop.imgDataUrl
    },

    employeeId () {
      return this.$route.params.employee_id
    },

    isCreationMode () {
      return this.$route.path.includes('add')
    },

    title () {
      return this.isCreationMode
        ? this.$t('pages.administration.add_new_employee')
        : this.$t('pages.administration.edit_employee')
    }
  },

  watch: {
    async employeeId (value) {
      if (value === undefined || value === 'add') {
        const clearForm = {
          firstName: '',
          lastName: '',
          email: '',
          description: '',
          phoneNumber: '',
          department: {},
          location: {},
          position: {},
          profilePictureUrl: ''
        }
        await this.$store.commit('employees/SET_CURRENT_EMPLOYEE', clearForm)
      } else {
        await this.openEmployee(this.employeeId)
      }
      if (value !== undefined) {
        this.loadDepartments()
        this._loadPositions()
        this.loadLocations()
      }
      this.clearErrors()
      this.initForm()
    },

    'userInfoForm.department' (value) {
      if (this.userInfoForm.position &&
            this.userInfoForm.position.department && this.userInfoForm.position.department.id !== (value && value.id)) {
        this.userInfoForm.position = { ...this.nullablePosition }
      }
      this.filteredPositions = [...this.positions.filter(el => !value || ((el.department && el.department.id) === value.id))]
      if (!this.filteredPositions.length) {
        this.userInfoForm.position = { ...this.nullablePosition }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
</style>
